import fetch from 'isomorphic-fetch'
import Cookie from 'js-cookie'
import qs from 'qs'
import { checkHttpStatus,
    handleNetworkError,
    parseJSON,
    getActiveCompany,
    checkPrices,
    hasActiveCompany,
} from '../../utils'
import jwtDecode from 'jwt-decode';
import { listSerializer, listProductsSerializer } from './serializers'
import { store } from '../../store'
import TokenStorage from '../../storages/token-storage'

export const REQUEST_LISTS = 'REQUEST_LISTS'
export const RECEIVE_LISTS = 'RECEIVE_LISTS'
export const REQUEST_LISTS_OVERVIEW = 'REQUEST_LISTS_OVERVIEW'
export const RECEIVE_LISTS_OVERVIEW = 'RECEIVE_LISTS_OVERVIEW'
export const REQUEST_LISTS_SINGLE = 'REQUEST_LISTS_SINGLE'
export const RECEIVE_LISTS_SINGLE = 'RECEIVE_LISTS_SINGLE'
export const RECEIVE_LISTS_ERROR = 'RECEIVE_LISTS_ERROR'
export const CREATE_LIST_ERROR = 'CREATE_LIST_ERROR'
export const CREATE_LIST_SUCCESS = 'CREATE_LIST_SUCCESS'
export const REQUEST_CREATE_LIST = 'REQUEST_CREATE_LIST'
export const DELETE_LIST_ERROR = 'DELETE_LIST_ERROR'
export const DELETE_LIST_SUCCESS = 'DELETE_LIST_SUCCESS'
export const REQUEST_DELETE_LIST = 'REQUEST_DELETE_LIST'
export const REQUEST_FREQUENT_PRODUCTS = 'REQUEST_FREQUENT_PRODUCTS'
export const RECEIVE_FREQUENT_PRODUCTS = 'RECEIVE_FREQUENT_PRODUCTS'
export const REQUEST_FREQUENT_PRODUCTS_OVERVIEW = 'REQUEST_FREQUENT_PRODUCTS_OVERVIEW'
export const RECEIVE_FREQUENT_PRODUCTS_OVERVIEW = 'RECEIVE_FREQUENT_PRODUCTS_OVERVIEW'
export const RECEIVE_FREQUENT_PRODUCTS_ERROR = 'RECEIVE_FREQUENT_PRODUCTS_ERROR'
export const RECEIVE_RECENT_SALES = 'RECEIVE_RECENT_SALES'
export const RECEIVE_RECENT_SALES_ERROR = 'RECEIVE_RECENT_SALES_ERROR'
export const RECEIVE_LIST_SALE_SUCCESS = 'RECEIVE_LIST_SALE_SUCCESS'
export const RECEIVE_LIST_SALE_ERROR = 'RECEIVE_LIST_SALE_ERROR'
export const REQUEST_LIST_SALE = 'REQUEST_LIST_SALE'
export const REQUEST_PROMOTION_LISTS = 'REQUEST_PROMOTION_LISTS'
export const RECEIVE_PROMOTION_LISTS = 'RECEIVE_PROMOTION_LISTS'
export const RECEIVE_PROMOTION_LISTS_ERROR = 'RECEIVE_PROMOTION_LISTS_ERROR'
export const SET_ACTIVE_LIST = 'SET_ACTIVE_LIST'


export const requestLists = () => {
    return {
        type: REQUEST_LISTS,
    };
};

export const requestListsOverview = () => {
    return {
        type: REQUEST_LISTS_OVERVIEW,
    };
};

export const requestSingleList = () => {
    return {
        type: REQUEST_LISTS_SINGLE,
    };
};

export const receiveLists = (response) => {
    return {
        type: RECEIVE_LISTS,
        data: response,
    };
};

export const receiveListsOverview = (response) => {
    return {
        type: RECEIVE_LISTS_OVERVIEW,
        data: response,
    };
};

export const receiveSingleList = (response) => {
    return {
        type: RECEIVE_LISTS_SINGLE,
        data: response,
    };
};

export const requestListSale = (sale_id) => {
    return {
        type: REQUEST_LIST_SALE,
        sale_id: sale_id
    }
}

export const requestFrequentProducts = () => {
    return {
        type: REQUEST_FREQUENT_PRODUCTS,
    };
};
export const requestFrequentProductsOverview = () => {
    return {
        type: REQUEST_FREQUENT_PRODUCTS_OVERVIEW,
    };
};

export const requestPromotionLists = () => {
    return {
        type: REQUEST_PROMOTION_LISTS,
    };
}

export const receiveListSaleSuccess = (sale_id, response) => {
    return {
        type: RECEIVE_LIST_SALE_SUCCESS,
        sale_id: sale_id,
        data: response
    }
}

export const receiveListSaleError = (sale_id) => {
    return {
        type: RECEIVE_LIST_SALE_ERROR,
        sale_id: sale_id
    }
}


export const receiveFrequentProducts = (response, extra) => {
    return {
        type: RECEIVE_FREQUENT_PRODUCTS,
        data: response,
        extra: extra,
    };
};
export const receiveFrequentProductsOverview = (response) => {
    return {
        type: RECEIVE_FREQUENT_PRODUCTS_OVERVIEW,
        data: response,
    };
};

export const receiveFrequentProductsError = (response) => {
    return {
        type: RECEIVE_FREQUENT_PRODUCTS_ERROR
    };
};

export const receivePromotionLists = (response) => {
    return {
        type: RECEIVE_PROMOTION_LISTS,
        data: response,
    };
}

export const receivePromotionListsError = (response) => {
    return {
        type: RECEIVE_PROMOTION_LISTS_ERROR
    };
}

export const receiveRecentSales = (response) => {
    return {
        type: RECEIVE_RECENT_SALES,
        data: response,
    };
}

export const receiveRecentSalesError = (response) => {
    return {
        type: RECEIVE_RECENT_SALES_ERROR
    };
}


export const receiveListsError = () => {
    return {
        type: RECEIVE_LISTS_ERROR,
    };
};


export const requestCreateList = () => {
    return {
        type: REQUEST_CREATE_LIST,
    };
};

export const createListSuccess = (response, show_steps) => {
    return {
        type: CREATE_LIST_SUCCESS,
        data: {...response, show_steps: show_steps},
    };
};

export const createListError = () => {
    return {
        type: CREATE_LIST_ERROR,
    };
};

export const requestDeleteList = () => {
    return {
        type: REQUEST_DELETE_LIST,
    };
};

export const deleteListSuccess = (response) => {
    return {
        type: DELETE_LIST_SUCCESS,
        data: {...response},
    };
};

export const deleteListError = () => {
    return {
        type: DELETE_LIST_ERROR,
    };
};

export const setActiveList = (obj) => {
    return{
        type: SET_ACTIVE_LIST,
        key: obj.key,
        value: obj.value
    }
}

export const fetchListSale = (sale_id) => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");

        dispatch(requestListSale(sale_id));

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/sales/?sale_id=${sale_id}`, {
            method: 'GET',
            headers: headers
        })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(checkPrices(dispatch, 'frequent'))
        .then(response => {
            try {
                dispatch(receiveListSaleSuccess(sale_id, response));
            } catch (e) {
                dispatch(receiveListSaleError(sale_id))
            }
        })
        .catch(handleNetworkError);
    };
}

export const patchList = (data) => {
    return dispatch => {
        const state = store.getState()
        const account_id = getActiveCompany(state)

        const authToken = TokenStorage.getItem("token");

        dispatch(requestCreateList());

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.CSRF_TOKEN,
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        const { id, ...bodyParams } = data;
        if (bodyParams.products) {
            bodyParams.products = listProductsSerializer(bodyParams.products)
        }
        if (bodyParams.removedProducts) {
            bodyParams.removedProducts = listProductsSerializer(bodyParams.removedProducts)
        }
        const body = {};
        for (const key in bodyParams) {
            if (['id', 'name', 'products', 'show_steps', 'removedProducts'].indexOf(key) !== -1) {
                body[key] = bodyParams[key];
            }
        }
        if (account_id != '') {
            body.company = account_id
        }
        return fetch(`/api/v1/lists/${id}/`, {
            method: 'PATCH',
            headers: headers,
            body: JSON.stringify(body),
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(createListSuccess(response, false));
            } catch (e) {
                dispatch(createListError(response))
            }
        })
        .catch(handleNetworkError);
    };
}

export const createList = (name, products, updateList=false, id=null, show_steps=false, is_promotion_list=false) => {
    return dispatch => {
        let state = store.getState()
        let account_id = getActiveCompany(state)
        
        let authToken = TokenStorage.getItem("token");
        
        dispatch(requestCreateList());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        let body = ''
        if (account_id != '') {
            body = JSON.stringify(listSerializer(name, products, id=id, account_id=account_id, show_steps=show_steps))
        } else {
            body = JSON.stringify(listSerializer(name, products, id=id, show_steps=show_steps))
        }
        var query = {}
        if(account_id !== ''){
            query.company = account_id
        }
        if(is_promotion_list){
            query.is_promotion_list = true
        }

        return fetch(`/api/v1/lists/${id ? `${id}/` : ''}?${qs.stringify(query)}`, {
            method: updateList ? 'PUT' : 'POST',
            headers: headers,
            body: body,
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(createListSuccess(response, show_steps));
            } catch (e) {
                dispatch(createListError(response))
            }
        })
        .catch(handleNetworkError);
    };
}
export const addListToCompany = (name, products, account_id, updateList=false, id=null, show_steps=false, is_promotion_list=false) => {
    return dispatch => {
        let state = store.getState()
        
        let authToken = TokenStorage.getItem("token");
        
        dispatch(requestCreateList());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        let body = ''
        if (account_id != '') {
            body = JSON.stringify(listSerializer(name, products, id=id, account_id=account_id, show_steps=show_steps))
        } else {
            body = JSON.stringify(listSerializer(name, products, id=id, show_steps=show_steps))
        }
        var query = {}
        if(account_id !== ''){
            query.company = account_id
        }
        if(is_promotion_list){
            query.is_promotion_list = true
        }

        return fetch(`/api/v1/lists/${id ? `${id}/` : ''}?${qs.stringify(query)}`, {
            method: updateList ? 'PUT' : 'POST',
            headers: headers,
            body: body,
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(createListSuccess(response, show_steps));
            } catch (e) {
                dispatch(createListError(response))
            }
        })
        .catch(handleNetworkError);
    };
}

export const updateList = (list) => {
    return createList(list.name, list.products, true, list.id, list.show_steps, list.is_promotion_list)
}

export const createPromotionList = (name, products, updateList=false, id=null) => {
    var show_steps = false
    return createList(name, products, updateList, id, show_steps, true)
}

export const fetchPromotionLists = () => {
    return dispatch => {

        dispatch(requestPromotionLists());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        let authToken = TokenStorage.getItem("token");

        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        let state = store.getState()

        fetch(`/promotion_pages_products/`, {
            headers: headers
        })
        .then(parseJSON)
        .then(checkPrices(dispatch, 'lists'))
        .then(r => {
            try {
                dispatch(receivePromotionLists(r));
            } catch (e) {
                dispatch(receivePromotionListsError(r));
            }
        })
    }
}


export const fetchFrequentProducts = (parameters="limit=50", extra=false) => {
    return dispatch => {

        dispatch(requestFrequentProducts());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        let authToken = TokenStorage.getItem("token");

        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        let state = store.getState()
        if (hasActiveCompany(state)) {
            parameters += `&company=${state.user.activeCompany.account_id}`
        }

        if (parameters !== "") {
            parameters = `?${parameters}`
        }

        fetch(`/api/v1/frequent_products/${parameters}`, {
            headers: headers
        })
        .then(parseJSON)
        .then(checkPrices(dispatch, 'frequent'))
        .then(r => {
            try {
                if(r.count > r.results.length && r.next!=null) {
                    dispatch(fetchFrequentProducts(`limit=${r.count-50}&offset=50`, true))
                }
                dispatch(receiveFrequentProducts(r, extra));
            } catch (e) {
                dispatch(receiveFrequentProductsError(r));
            }
        })
    }
}

export const fetchFrequentProductsOverview = (parameters="", extra=false) => {
    return dispatch => {

        dispatch(requestFrequentProductsOverview());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        let authToken = TokenStorage.getItem("token");

        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        let state = store.getState()
        if (hasActiveCompany(state)) {
            parameters += `&company=${state.user.activeCompany.account_id}`
        }

        if (parameters !== "") {
            parameters = `?${parameters}`
        }

        fetch(`/api/v1/frequent_products/overview/${parameters}`, {
            headers: headers
        })
        .then(parseJSON)
        .then(checkPrices(dispatch, 'frequent'))
        .then(r => {
            dispatch(fetchPromotionLists())
            try {
                dispatch(receiveFrequentProductsOverview(r, extra));
            } catch (e) {
                dispatch(receiveFrequentProductsError(r));
            }
        })
    }
}

export const fetchListsOverview = (parameters="") => {
    return dispatch => {
        
        let state = store.getState()
        if (hasActiveCompany(state)) {
            parameters += `&company=${state.user.activeCompany.account_id}`
        }

        let authToken = TokenStorage.getItem("token");
        
        dispatch(requestListsOverview());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        

            if (parameters !== "") {
                parameters = `?${parameters}`
            }

            return fetch(`/api/v1/lists/overview/${parameters}`, {
                headers: headers
            })
            .then(parseJSON)
            .then(checkPrices(dispatch, 'lists'))
            .then(response => {

                try {
                    dispatch(receiveListsOverview(response));
                } catch (e) {
                    dispatch(receiveListsError(response))
                }


            })
            .catch(handleNetworkError);
        }
    };
};



export const fetchLists = (parameters="") => {
    return dispatch => {
        
        let state = store.getState()
        if (hasActiveCompany(state)) {
            parameters += `&company=${state.user.activeCompany.account_id}`
        }

        let authToken = TokenStorage.getItem("token");
        
        dispatch(requestLists());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        

            if (parameters !== "") {
                parameters = `?${parameters}`
            }

            return fetch(`/api/v1/lists/${parameters}`, {
                headers: headers
            })
            .then(parseJSON)
            .then(checkPrices(dispatch, 'lists'))
            .then(response => {

                dispatch(fetchFrequentProducts())
                dispatch(fetchPromotionLists())

                // fetch(`/api/v1/sales/${parameters}&limit=3`, {
                //     headers: headers
                // })
                // .then(parseJSON)
                // .then(rr => {
                //     try {
                //         dispatch(receiveRecentSales(rr));
                //     } catch (e) {
                //         dispatch(receiveRecentSalesError(rr));
                //     }
                // })

                try {
                    dispatch(receiveLists(response));
                } catch (e) {
                    dispatch(receiveListsError(response))
                }


            })
            .catch(handleNetworkError);
        }
    };
};


export const fetchSingleList = (id) => {
    return dispatch => {

        let parameters = ""
        
        let state = store.getState()
        if (hasActiveCompany(state)) {
            parameters += `&company=${state.user.activeCompany.account_id}`
        }

        let authToken = TokenStorage.getItem("token");
        
        dispatch(requestSingleList());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        

            if (parameters !== "") {
                parameters = `?${parameters}`
            }

            return fetch(`/api/v1/lists/${id}`, {
                headers: headers
            })
            .then(parseJSON)
            .then(checkPrices(dispatch, 'listsingle'))
            .then(response => {
                try {
                    dispatch(receiveSingleList(response));
                } catch (e) {
                    dispatch(receiveSingleListError(response))
                }


            })
            .catch(handleNetworkError);
        }
    };
};

export const deleteList = (id) => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");
        
        dispatch(requestDeleteList());

        let parameters = '';
        let state = store.getState()
        if (hasActiveCompany(state)) {
            parameters = `?company=${state.user.activeCompany.account_id}`
        }

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.CSRF_TOKEN,
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/lists/${id}/${parameters}`, {
            method: 'DELETE',
            headers: headers,
        })
        .then(response => {

            try {
                dispatch(deleteListSuccess(response));
            } catch (e) {
                dispatch(deleteListError(response))
            }


        })
        .catch(handleNetworkError);
    };
}