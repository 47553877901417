import fetch from 'isomorphic-fetch'
import TokenStorage from '../../storages/token-storage'
import { store } from '../../store'
import { checkHttpStatus, getAddonProducts, handleNetworkError, parseJSON, trackGAEvent } from '../../utils'

export const CART_ADD_PRODUCTS = 'CART_ADD_PRODUCTS'
export const CART_ADD_PRODUCTS_ERROR = 'CART_ADD_PRODUCTS_ERROR'
export const CART_REMOVE_PRODUCT_SUCCESS = 'CART_REMOVE_PRODUCT_SUCCESS'
export const CART_REMOVE_PRODUCT_ERROR = 'CART_REMOVE_PRODUCT_ERROR'
export const CART_UPDATE_PRODUCT_QUANTITY_SUCCESS = 'CART_UPDATE_PRODUCT_QUANTITY_SUCCESS'
export const CART_UPDATE_PRODUCT_QUANTITY_ERROR = 'CART_UPDATE_PRODUCT_QUANTITY_ERROR'
export const CART_ADD_SALE_PRODUCTS = 'CART_ADD_SALE_PRODUCTS'
export const CART_ADD_SALE_PRODUCTS_ERROR = 'CART_ADD_SALE_PRODUCTS_ERROR'
export const CART_EMPTY = 'CART_EMPTY'
export const CART_UPDATE_PACKAGING = 'CART_UPDATE_PACKAGING'
export const CART_FETCH_SERVER_SUCCESS = 'CART_FETCH_SERVER_SUCCESS'
export const CART_FETCH_SERVER_ERROR = 'CART_FETCH_SERVER_ERROR'
export const CART_FETCH_OTHERS_SUCCESS = 'CART_FETCH_OTHERS_SUCCESS'
export const CART_FETCH_OTHERS_ERROR = 'CART_FETCH_OTHERS_ERROR'
export const EMPTY_CART_SUCCESS = 'EMPTY_CART_SUCCESS'
export const EMPTY_CART_ERROR = 'EMPTY_CART_ERROR'
export const IGNORE_CURRENT_LOCAL_CART = 'IGNORE_CURRENT_LOCAL_CART'
export const CART_FETCH_OTHERS_REQUEST = 'CART_FETCH_OTHERS_REQUEST'


const _addProducts = (products, replaceProducts, cart_id) => {
    trackGAEvent('add_to_cart', {})
    return {
        type: CART_ADD_PRODUCTS,
        products: products,
        replaceProducts: replaceProducts,
        cart_id: cart_id
    };
}

const addProductToServerError = (response) => {
    return {
        type: CART_ADD_PRODUCTS_ERROR
    }
}

const addSaleProductsSuccess = (response, sale_id) => {
    trackGAEvent('add_to_cart', {})
    return {
        type: CART_ADD_SALE_PRODUCTS,
        products: response.results
    }
}

const addSaleProductsError = (response) => {
    return {
        type: CART_ADD_SALE_PRODUCTS_ERROR
    }
}

export const addProduct = (product, quantity) => {
    trackGAEvent('add_to_cart', {})
    if (quantity === "-") {
        quantity = 1
    }

    return dispatch => {
        let authToken = TokenStorage.getItem("token");

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.CSRF_TOKEN,
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/cart/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({'products': [{'product_id': product.item_id, 'quantity': quantity}], 'replaceProducts': false})
        })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(_addProducts([{product:product, quantity: quantity}], null, response.cart_id))
                getAddonProducts(dispatch)
            } catch (e) {
                dispatch(addProductToServerError(e))
            }
        })
    };
};

export const emptyCartSuccess = (response) => {
    return {
        type: EMPTY_CART_SUCCESS,
        data: {...response},
    };
};

export const emptyCartError = () => {
    return {
        type: EMPTY_CART_ERROR,
    };
};

export const emptyCart = (id) => {
    trackGAEvent('empty_cart', {})
    return dispatch => {
        let authToken = TokenStorage.getItem("token");

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.CSRF_TOKEN,
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/cart/${id}/`, {
            method: 'DELETE',
            headers: headers,
        })
        .then(response => {

            try {
                dispatch(emptyCartSuccess(response));
                dispatch(fetchServerCartItems())
            } catch (e) {
                dispatch(emptyCartError(response))
            }
        })
        .catch(handleNetworkError);
    };
};

export const addProducts = (products, replaceProducts) => {
    trackGAEvent('add_to_cart', {})

    return dispatch => {
        let authToken = TokenStorage.getItem("token");

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.CSRF_TOKEN,
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        let product_list = []
        products.map((p) => {
            product_list.push({'product_id':p.product.item_id, 'quantity': p.quantity})
        })
        return fetch(`/api/v1/cart/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({'products': product_list, 'replaceProducts': replaceProducts})
        })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(_addProducts(products, replaceProducts, response.cart_id))
                getAddonProducts(dispatch)
            } catch (e) {
                dispatch(addProductToServerError(e))
            }
        })
    };
}

export const addSaleProducts = (sale_id) => {

    return dispatch => {
        let authToken = TokenStorage.getItem("token");

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/sales/?sale_id=${sale_id}`, {
            method: 'GET',
            headers: headers
        })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(addSaleProductsSuccess(response, sale_id));
            } catch (e) {
                dispatch(addSaleProductsError(e))
            }
        })
    };
}


export const removeProductSuccess = (product_id) => {
    return {
        type: CART_REMOVE_PRODUCT_SUCCESS,
        product_id: product_id
    };
};

export const removeProductError = () => {
    return {
        type: CART_REMOVE_PRODUCT_ERROR,
    };
};

export const removeProduct = (product_id) => {
    trackGAEvent('remove_from_cart', {})
    const state = store.getState()
    const cart_id = state.cart.cart_id

    return dispatch => {
        let authToken = TokenStorage.getItem("token");

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.CSRF_TOKEN,
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/cart/${cart_id}/`, {
            method: 'PATCH',
            headers: headers,
            body: JSON.stringify({'product': product_id})
        })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(removeProductSuccess(product_id))
                getAddonProducts(dispatch)
            } catch (e) {
                dispatch(removeProductError(e))
            }
        })
    };
};

export const updateProductQuantitySuccess = (product_id, quantity) => {
    return {
        type: CART_UPDATE_PRODUCT_QUANTITY_SUCCESS,
        product_id: product_id,
        quantity: quantity
    };
};

export const updateProductQuantityError = () => {
    return {
        type: CART_UPDATE_PRODUCT_QUANTITY_ERROR,
    };
};

export const setProductQuantity = (quantity, product_id) => {
    trackGAEvent('set_cart_product_quantity', {
        event_category: 'ecommerce'
    })
    return dispatch => {
        let authToken = TokenStorage.getItem("token");

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.CSRF_TOKEN,
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/cart/set_quantity/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({'product_id': product_id, 'quantity': quantity})
        })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(updateProductQuantitySuccess(product_id, quantity))
            } catch (e) {
                dispatch(updateProductQuantityError(e))
            }
        })
    };
}


export const updatePackaging = (product, updateAll=false) => {
    return {
        type: CART_UPDATE_PACKAGING,
        product: product,
        updateAll: updateAll
    }
}

const fetchServerCartItemsSuccess = (response) => {
    return {
        type: CART_FETCH_SERVER_SUCCESS,
        products: response
    }
}

const fetchServerCartItemsError = (response) => {
    return {
        type: CART_FETCH_SERVER_ERROR
    }
}

export const fetchServerCartItems = (companyId="") => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/cart/cart_items/?companyId=${companyId}`, {
            method: 'GET',
            headers: headers,
        })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(fetchServerCartItemsSuccess(response));
            } catch (e) {
                dispatch(fetchServerCartItemsError(e))
            }
        })
    };
}

export const ignoreCurrentLocalCart = () => {
    return {
        type: IGNORE_CURRENT_LOCAL_CART,
    }
}

const requestFetchOtherCarts = () => {
    return {
        type: CART_FETCH_OTHERS_REQUEST,
    }
}
const fetchOtherCartsSuccess = (response) => {
    return {
        type: CART_FETCH_OTHERS_SUCCESS,
        carts: response
    }
}

const fetchOtherCartsError = (response) => {
    return {
        type: CART_FETCH_OTHERS_ERROR
    }
}

export const fetchOtherCarts = () => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");
        dispatch(requestFetchOtherCarts());
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/cart/get_all_user_carts`, {
            method: 'GET',
            headers: headers,
        })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(fetchOtherCartsSuccess(response));
            } catch (e) {
                dispatch(fetchOtherCartsError(e))
            }
        })
    };
}